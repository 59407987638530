<template>
    <b-container>
        <b-row>
            <b-col sm="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <div class="text-center">
                            <h5 class="card-title"><i class="ri-edit-line"></i> {{ $t('globalTrans.personal_information') }}</h5>
                        </div>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show='formLoad'>
                            <b-col sm="12">
                            <form-input :data="formInputData" ref="form" @change="setData" @submit="register">
                                <template v-slot:submitButton="">
                                    <b-button-group>
                                        <b-button type="submit" variant="primary"
                                                class="mr-1 rounded"
                                                title="Draft copy will be saved, you can edit this anytime."
                                                @click="isFinal = false">{{ $t('externalUserIrrigation.save_draft') }}
                                            <i class="far fa-save ml-1"></i>
                                        </b-button>
                                        <b-button type="submit"
                                                variant="success"
                                                title="Final Save cannot be reverted!"
                                                class="ml-1 rounded"
                                                @click="isFinal = true">{{ $t('externalUserIrrigation.final_save') }}
                                            <i class="fas fa-lock ml-1"></i>
                                        </b-button>
                                    </b-button-group>
                                </template>
                            </form-input>
                            </b-col>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { farmerProfileStore, farmerProfileUpdate } from '../../api/routes'
import FormInput from '@/components/structure/form/validationForm/FormInput'
import { Common, Repository } from '@/mixins/helper-functions'
import Cuustom from '../../../../../store/custom'
import { EventBus } from '@/EventBusLayout'
export default {
    mixin: [Common],
    components: {
        FormInput
    },
    created () {
        const tmp = this.getData()
        if (tmp.farmer_id) {
            this.farmer_id = parseInt(tmp.farmer_id)
            Object.freeze(tmp)
            const mobile = this.$store.state.Auth.authUser.mobile_no
            this.farmerBasicInfo = Object.assign(this.farmerBasicInfo, tmp, { mobile_no: mobile })

            this.status = this.farmerBasicInfo.status
            const allowedStatus = [
                this.$store.state.ExternalUserIrrigation.farmerBasicInfoStatus.find(item => item.text === 'Draft').value
            ]
            if (!allowedStatus.includes(this.farmerBasicInfo.status)) {
                return this.$router.push('/irrigation/my-profile')
            }
        }
    },
    data () {
        return {
            formLoad: false,
            isFinal: false,
            farmerBasicInfo: {
                far_division_id: '',
                far_district_id: '',
                far_upazilla_id: '',
                far_union_id: '',
                status: ''
            },
            districtList: [],
            upazilaList: [],
            unionList: [],
            farmer_id: ''
        }
    },
    computed: {
        ...mapGetters({
            serviceUrl: 'Portal/serviceUrl'
        }),
        divisionList () {
            return this.$store.state.ExternalUserIrrigation.commonObj.divisionList
        },
        formInputData () {
            return {
                chunk: true,
                buttonLocale: this.farmer_id ? 'globalTrans.update' : 'globalTrans.save_application_form',
                buttonClass: 'btn btn-primary btn-block',
                dataParent: this.farmerBasicInfo,
                data: [
                    {
                        head: {
                            title: ''
                        },
                        items: [
                            {
                                name: 'attachment',
                                type: 'ImageFile',
                                label: 'globalTrans.profileImage',
                                link: irriSchemeServiceBaseUrl + 'download-attachment?file=uploads/farmer-basic/original/' + this.farmerBasicInfo.attachment,
                                uploadLabel: 'Upload Image',
                                caption: `
                                        <span>Only </span>
                                        <span> </span>
                                        <b-link href="javascript:void();">.jpg</b-link>
                                        <b-link href="javascript:void();">.png</b-link>
                                        <b-link href="javascript:void();">.jpeg</b-link>
                                        <span>allowed</span>`,
                                rules: (this.farmerBasicInfo.id) ? 'ext:png,jpeg,jpg' : 'required|ext:png,jpeg,jpg'
                            },
                            {
                                name: 'nid_photo',
                                type: 'ImageFile',
                                label: 'globalTrans.nid_photo',
                                link: irriSchemeServiceBaseUrl + 'download-attachment?file=uploads/farmer-basic-nid/original/' + this.farmerBasicInfo.nid_photo,
                                uploadLabel: 'Upload NID Image',
                                caption: `
                                        <span>Only </span>
                                        <span> </span>
                                        <b-link href="javascript:void();">.jpg</b-link>
                                        <b-link href="javascript:void();">.png</b-link>
                                        <b-link href="javascript:void();">.jpeg</b-link>
                                        <span>allowed</span>`,
                                rules: (this.farmerBasicInfo.id) ? 'ext:png,jpeg,jpg' : 'required|ext:png,jpeg,jpg'
                            },
                            {
                                name: 'name',
                                type: 'Input',
                                label: 'globalTrans.name_en',
                                readOnly: true
                            },
                            {
                                name: 'name_bn',
                                type: 'Input',
                                label: 'globalUserData.name_bn',
                                readOnly: true
                            },
                            {
                                name: 'gender',
                                type: 'Select',
                                label: 'externalUserIrrigation.gender',
                                options: this.genderList
                            },
                            {
                                name: 'email',
                                type: 'Input',
                                label: 'globalUserData.email',
                                readOnly: true
                            },
                            {
                                name: 'nid',
                                type: 'Input',
                                inputType: 'number',
                                label: 'externalUserIrrigation.nid'
                            },
                            {
                                name: 'mobile_no',
                                type: 'Input',
                                label: 'globalUserData.mobile_no',
                                readOnly: true
                            },
                            {
                                name: 'father_name',
                                type: 'Input',
                                label: 'externalUserIrrigation.father_namef'
                            },
                            {
                                name: 'father_name_bn',
                                type: 'Input',
                                label: 'externalUserIrrigation.father_name_bn'
                            },
                            {
                                name: 'mother_name',
                                type: 'Input',
                                label: 'externalUserIrrigation.mother_namef'
                            },
                            {
                                name: 'mother_name_bn',
                                type: 'Input',
                                label: 'externalUserIrrigation.mother_name_bn'
                            },
                            {
                                name: 'far_village',
                                type: 'Input',
                                label: 'externalUserIrrigation.villagef'
                            },
                            {
                                name: 'far_village_bn',
                                type: 'Input',
                                label: 'farmerOperator.village_bn'
                            },
                            {
                                name: 'far_division_id',
                                type: 'Select',
                                label: 'org_pro_division.division',
                                options: this.divisionList,
                                rules: 'required|min_value:1'
                            },
                            {
                                name: 'far_district_id',
                                type: 'Select',
                                label: 'org_pro_district.district',
                                options: this.districtList
                            },
                            {
                                name: 'far_upazilla_id',
                                type: 'Select',
                                label: 'org_pro_upazilla.upazilla',
                                options: this.upazilaList
                            },
                            {
                                name: 'far_union_id',
                                type: 'Select',
                                label: 'org_pro_union.union',
                                options: this.unionList
                            }
                        ]
                    }
                ]
            }
        },
        genderList () {
            const genderList = Cuustom.genderListEx.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
            return genderList
        }
    },
    watch: {
        isFinal: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.farmerBasicInfo.status = newVal ? 2 : 1
            }
        },
        'farmerBasicInfo.far_division_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.districtList = this.getDistrictList(newVal)
            }
        },
        'farmerBasicInfo.far_district_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.upazilaList = this.getUpazilaList(newVal)
            }
        },
        'farmerBasicInfo.far_upazilla_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.unionList = this.getUnionList(newVal)
            }
        }
    },
    methods: {
        checkUser () {
            RestApi.getData(irriSchemeServiceBaseUrl, 'farmer-basic-infos/check-user?with_data=' + false).then(response => {
                if (response.success) {
                this.$store.dispatch('ExternalUserIrrigation/getFarmerBasicInfoDetails', response.data)
                }
            })
        },
        setData (data) {
            this.farmerBasicInfo = Object.assign(this.farmerBasicInfo, data)
        },
        getData () {
            return this.$store.state.ExternalUserIrrigation.farmerBasicInfo
        },
        async register () {
            this.formLoad = true
            const result = await Repository.create({
                data: this.farmerBasicInfo,
                baseURL: irriSchemeServiceBaseUrl,
                storeAPI: farmerProfileStore,
                updateAPI: farmerProfileUpdate,
                itemId: this.farmer_id
            })
            this.formLoad = false
            if (result.success === true) {
                EventBus.$emit('EXTERNAL_USER_COMMON_PROFILE')
                setTimeout(async () => {
                    let url = this.serviceUrl.externalPanelCurrentServiceUrl
                    url = (url !== undefined && result.data.status === 2 && url.indexOf('irrigation') !== -1) ? url : '/irrigation/my-profile'
                    // await this.$router.push('/irrigation/my-profile')
                    await this.$router.push(url)
                }, 1000)
            } else {
                this.$refs.form.handleErorr(result.errors)
            }
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList
            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }
            return districtList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList

            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getUnionList (upazilaId = null) {
            const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList
            if (upazilaId) {
                return unionList.filter(union => union.upazilla_id === upazilaId)
            }
            return unionList
        }
    }
}
</script>
